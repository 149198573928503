/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "../core/Logger.js";
import { getDeepValue as r } from "../core/object.js";
import { replace as e } from "../core/string.js";
import { formatDate as n } from "./date.js";
import { formatNumber as o } from "./number.js";
const i = () => t.getLogger("esri.intl.substitute");
function s(t, r, n = {}) {
  const {
    format: o = {}
  } = n;
  return e(t, t => u(t, r, o));
}
function u(t, e, n) {
  let o, i;
  const s = t.indexOf(":");
  if (-1 === s ? o = t.trim() : (o = t.slice(0, s).trim(), i = t.slice(s + 1).trim()), !o) return "";
  const u = r(o, e);
  if (null == u) return "";
  const m = (i ? n?.[i] : null) ?? n?.[o];
  return m ? c(u, m) : i ? a(u, i) : f(u);
}
function c(t, r) {
  switch (r.type) {
    case "date":
      return n(t, r.intlOptions);
    case "number":
      return o(t, r.intlOptions);
    default:
      return i().warn("missing format descriptor for key {key}"), f(t);
  }
}
function a(t, r) {
  switch (r.toLowerCase()) {
    case "dateformat":
      return n(t);
    case "numberformat":
      return o(t);
    default:
      return i().warn(`inline format is unsupported since 4.12: ${r}`), /^(dateformat|datestring)/i.test(r) ? n(t) : /^numberformat/i.test(r) ? o(t) : f(t);
  }
}
function f(t) {
  switch (typeof t) {
    case "string":
      return t;
    case "number":
      return o(t);
    case "boolean":
      return "" + t;
    default:
      return t instanceof Date ? n(t) : "";
  }
}
export { s as substitute };